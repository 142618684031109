import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "YThmMzFmOGRlNmQxZDRlMjkxMzgwYjU5MDlkZDkzN2Q=",
	"Tenant-Access-Key": "bwa_qx8ioGwGuA",
};

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";

export const api = axios.create({
	baseURL: BASE_URL,
	headers,
});
